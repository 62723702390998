<template>
	<v-app dark>
		<v-snackbar v-model="$store.state.showtips.show" top :color="$store.state.showtips.color" timeout="3000">{{ $store.state.showtips.msg }}</v-snackbar>
		<v-main app dark>
			<dv-border-box-8 :reverse="true">
				<LoginTep v-if="!$store.state.isLogin" />
				<bodyElWk v-if="$store.state.isLogin" />
				
			</dv-border-box-8>
		</v-main>
	</v-app>
</template>

<script>
import LoginTep from './components/LoginTep';
import bodyElWk from './components/bodyElWk';

export default {
	name: 'App',

	components: {
		LoginTep,
		bodyElWk
	},

	data: () => ({
		//
	}),
	created() {
		this.$store.commit('setIsLogin', localStorage.getItem('token') || false);
	}
};
</script>
<style>
html,
body {
	overflow: hidden;
}
.theme--light.v-application {
	background-color: #000023 !important;
	color: #d6d5f8 !important;
}
</style>
