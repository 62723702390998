import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import dataV from '@jiaminghi/data-view'
import api from './apirouter';
import store from './store'
Vue.use(dataV)
Vue.config.productionTip = false
Vue.prototype.$api = api;
window.vm = new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
