const util = require("@jiaminghi/c-render/lib/plugin/util")

export default {
	rheader: function(render) {
		const {
			area: [w, h],
		} = render
		var hgap = 2
		var points = [
			[0, 0],
			[0, h / 3 * hgap],
			[w / 5 * 2, h / 3 * hgap],
			[w / 2, h-10],
			[w / 5 * 3, h / 3 * hgap],
			[w, h / 3 * hgap],
			[w, 0]
		]

		return {
			name: 'polyline',
			animationCurve: 'easeOutBack',
			hover: true,
			drag: true,
			shape: {
				points,
				close: true,
			},
			style: {
				// fill: 'rgba(11, 73, 149, 0)',
				gradientType: 'linear',
				gradientWith: 'fill',
				gradientColor: ['rgba(13, 86, 221, 0.05)', 'rgba(11, 73, 149, 1.0)', 'rgba(6, 52, 253, 1.0)',
					'rgba(11, 73, 149, 1.0)', 'rgba(13, 86, 221, 0.05)'
				],
				gradientParams: [0, 0, w, 0],
				shadowBlur: 0,
				lineWidth: 10,
				shadowColor: 'rgba(33,133,38,0.1)',
				hoverCursor: 'pointer',
			},
			onMouseEnter(e) {
				this.animation('style', {
					shadowBlur: 20
				}, true)
				const pointsCloned = deepClone(this.shape.points)
				pointsCloned[2][1] += top * 0.3
				this.animation('shape', {
					points: pointsCloned
				})
			},
			onMouseOuter(e) {
				this.animation('style', {
					shadowBlur: 0
				}, true)
				const pointsCloned = deepClone(this.shape.points)
				pointsCloned[2][1] -= top * 0.3
				this.animation('shape', {
					points: pointsCloned
				})
			},
		}
	},
	rheaderLine: function(render) {
		const {
			area: [w, h],
		} = render
		var hgap = 2
		var points = [
			
			[0, h / 3 * hgap+5],
			[w / 5 * 2, h / 3 * hgap+5],
			[w / 2, h-5],
			[w / 5 * 3, h / 3 * hgap+5],
			[w, h / 3 * hgap+5],
			
		]
	
		return {
			name: 'polyline',
			animationCurve: 'easeOutBack',
			hover: true,
			drag: true,
			shape: {
				points,
			},
			style: {
				// fill: 'rgba(11, 73, 149, 0)',
				stroke: '#9ce5f4',
				gradientType: 'linear',
				gradientWith: 'stroke',
				gradientColor: ['rgba(13, 86, 221, 0.05)', 'rgba(11, 73, 149, 1.0)', 'rgba(6, 52, 253, 1.0)',
					'rgba(11, 73, 149, 1.0)', 'rgba(13, 86, 221, 0.05)'
				],
				gradientParams: [0, 0, w, 0],
				shadowBlur: 0,
				lineWidth: 2,

				
			}
			
		}
	}
}
