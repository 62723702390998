
var req = function(url = "", body = {}, headers = {}, method = "POST") {
	//https://mockapi.eolinker.com/Uj8WNQX0d65502098df8ecc1f06348d7aac9364b4d08923
	//https://zkcj.banlvit.com/wxappapi
	let host = 'https://shilvyou.banlvit.com/wxappapi'
	// let host = 'https://zkcj.banlvit.com/wxappapi'
	return new Promise((res, rej) => {
		let token = localStorage.getItem('token')||"";
		
		fetch(host + url, {
				method: method, // or 'PUT'
				body: JSON.stringify(body), // data can be `string` or {object}!
				headers: new Headers({
					'Content-Type': 'application/json',
					'token':token,
					...headers
				})
			}).then(resdata => resdata.json())
			.catch(error => {
				console.error('Error:', error)
				// alert('请求错误');
				rej(error)
			})
			.then(response => {
				if(response['code']==-1||!response||response['code']==-2){
					vm.$store.commit('setIsLogin',false)
					localStorage.removeItem('token')
					if(response){
						rej(response['msg'])
					}else{
						rej('访问出错')
					}
					return;
				}
				res(response)
			});
	})
}

//帐户登录
async function datav_login(){
	const {user,password} = arguments[0]||{};
	let default_value = {
		username:user,password:password
	}
	if(!user|| !password){
		return false;
	}
	var rulst = await req('/user/login',default_value).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}

//获取大屏基本数据
async function datav_homeBaseData(id){
	var rulst = await req('/datav/homeBaseData',{shitang_id:id}).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}
async function datav_queryHoliday(date){
	var rulst = await req('/sys/queryHoliday',{date:date}).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}
// 报送数据列表
async function datav_getReportList({start,end,page_size,page,type}){
	page_size  = page_size||40;
	page  = page||1;
	type  = type||0;
	var rulst = await req('/manager/getReportList',{start:start,end:end,page_size:page_size,page:page,type:type}).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data.data;
}
// 数据排行
async function datav_dataRanking({start,end,date_type,category_id}){
	var rulst = await req('/manager/dataRanking',{start:start,end:end,date_type:date_type,category_id:category_id}).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}
// 数据趋势
async function datav_dataTrend({start,end,date_type,type}){
	var rulst = await req('/manager/dataTrend',{start:start,end:end,date_type:date_type,type:0}).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}
// 数据对比
async function datav_dataComparison(opts){
	var rulst = await req('/manager/dataComparison',opts).catch(e=>{
		vm.$store.commit('setshowtips',{msg:e,color:'red'})
	})
	if(!rulst) return false;
	return rulst.data;
}

export default {
	datav_dataComparison,//数据对比
	datav_dataTrend,//数据趋势
	datav_dataRanking,//数据排行
	datav_getReportList,//报送数据列表
	datav_queryHoliday,//查询节假日数据
	datav_homeBaseData,//获取大屏基本数据
	datav_login,//帐户登录
}