<template>
  <v-container fluid style="height: 100%;" class="d-flex justify-center align-center">
	 
		<v-sheet color="transparent" dark width="460px" height="310px">
			<dv-border-box-11 title="登录系统">
				<v-sheet color="transparent" dark width="460px" height="310px">
					<div style="height: 58px;"></div>
					<v-container class="d-flex justify-center align-center">
						
							<v-sheet  color="transparent" width="400px">
								<v-text-field :rules="nameRules" required v-model="user" prepend-icon="mdi-account" color="indigo darken-1" label="请输入帐户名称" background-color="transparent"></v-text-field>
								<div style="height: 8px;"></div>
								<v-text-field type="password" :rules="nameRules"  required v-model="password" prepend-icon="mdi-lock" color="indigo darken-1" label="请输入帐户密码" background-color="transparent"></v-text-field>
								<div class="py-2"></div>
								<v-btn :loading="loadding" @click="login" color="indigo darken-4" block large><v-icon>mdi-lock-open-variant</v-icon><span class="pl-2" style="font-size: 21px;">确认登录</span></v-btn>
							</v-sheet>
						
					</v-container>
				</v-sheet>
			</dv-border-box-11>
		</v-sheet>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginTep',

    data: () => ({
		loadding:false,
		user:'',
		password:'',
		nameRules: [ v => !!v || '不能为空'],
    }),

	mounted() {
		
		this.user =localStorage.getItem('user',this.user)||''
		this.password =localStorage.getItem('password',this.password)||''
		this.$nextTick(function(){
			this.autologin();
		})
	},
	methods: {
		//当前大屏的token失效时，需要重新自动登录数据列表。
		autologin(){
			localStorage.removeItem('token');
			if(this.user&&this.password){
				//停止自动登录。
				// this.login();
			}
		},
		async login() {
			this.loadding=true;
			let rulst = await this.$api.datav_login({user:this.user,password:this.password}).catch(e=>{})
			this.loadding=false;
			if(!rulst){
				// this.$store.commit('setshowtips',{msg:'登录错误，请重试',color:'red'})
				return;
			}
			this.$store.commit('setshowtips',{msg:'登录成功',color:'green'})
			this.$store.commit('setIsLogin',true)
			
			localStorage.setItem('user',this.user)
			localStorage.setItem('password',this.password)
			localStorage.setItem('token',rulst)
			localStorage.removeItem('timedate')
		}
	},
  }
</script>
