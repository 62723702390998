<template>
	<div style="width: 100%;height: 100%;">
		<div
			v-if="showLoaddingDataV"
			style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 102;backdrop-filter: blur(10px);background-color: #00147675;"
			class="d-flex justify-center align-center"
		>
			<dv-loading>Loading...</dv-loading>
		</div>
		<headerBody>
			<div class="d-flex justify-center align-center title-wk">
				<div style="width: 35%;font-size: 16px;" class="d-flex justify-center align-center">
					<!-- //刷新数据 -->
					<div class="ml-4 d-flex align-center">
						<v-progress-circular color="#aaaaff" :value="(autoRefreshTimeNum / refreshTime) * 100"></v-progress-circular>
						<span style="font-size: 13px;white-space: nowrap;" class="mr-6 ml-2">({{ refreshTime - autoRefreshTimeNum }}秒后刷新)</span>
					</div>
					<dv-decoration-7 :color="['#546BFF', '#546BFF', '#546BFF']" style="width:400px;height:30px;">
						<div style="width: 400px;height: 50px;">
							<dv-border-box-10 :color="['#546BFF', '#546BFF', '#546BFF']">
								<div class="px-4 justify-center align-center" style="height: 100%;width: 100%;">
									<div style="height: 100%;width: 100%;line-height: 50px;text-align: center;">
										<span>{{ headerWeek.first }}</span>
										<span class="px-2">{{ headerWeek.two }}</span>
										<span>{{ headerWeek.three }}</span>
									</div>
								</div>
							</dv-border-box-10>
						</div>
					</dv-decoration-7>
				</div>
				<div style="width: 50%;" class="d-flex justify-center title">南昌文旅数据报送平台</div>
				<div style="width: 35%;padding-right: 15px;" class="d-flex justify-end">
					<div style="width: 100%;height: 60px;">
						<dv-border-box-10 :color="['#546BFF', '#546BFF', '#546BFF']">
							<div style="height: 100%;width: 100%;" class="d-flex justify-center align-center">
								<div style="width:90%" class=" d-flex align-center">
									<div class="d-flex align-center justify-center " style="flex-shrink: 0;">
										<v-radio-group @change="dateTypeChange" v-model="dateTypeSelected" row dense dark flat hide-details style="margin-top: 0;">
											<v-radio v-for="(item, index) in dateType" :key="index" :value="item.id" :label="item.text"></v-radio>
										</v-radio-group>
										<v-checkbox v-model="isShowJiejiariCheckbox" label="节假日" row dense dark flat hide-details></v-checkbox>
									</div>

									<div class="px-4"></div>
									<div style="">
										
										<v-select
										placeholder="请选择假日"
											v-if="isShowJiejiariCheckbox"
											style="height: 28px;"
											item-text="text"
											v-model="jiejiariList_selected"
											hide-details
											background-color="transparent"
											:items="jiejiariList"
											solo
											dense
											dark
											flat
											@change="jiejiariListChange"
										></v-select>

										<v-menu
											v-if="!isShowJiejiariCheckbox"
											dark
											flat
											ref="menu_start"
											v-model="menu_start"
											:close-on-content-click="false"
											
											transition="scale-transition"
											offset-y
											max-width="600px"
											min-width="auto"
											:nudge-right="-330"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-row @click="resetDate">
													<v-col cols="11" sm="6">
														<v-text-field
														@click="resetDate"
															dense
															dark
															flat
															hide-details
															v-model="date_sart"
															label="开始"
															readonly
															v-bind="attrs"
															v-on="on"
														></v-text-field>
													</v-col>
													<v-col cols="11" sm="6">
														<v-text-field @click="resetDate" dense dark flat hide-details v-model="date_end" label="结束" readonly v-bind="attrs" v-on="on"></v-text-field>
													</v-col>
												</v-row>
											</template>
											<div class="d-flex" style="width: 580px;">
												<div style="width: 290px;">
													<v-date-picker color="blue" locale="zh" v-model="old_data_start">
														<v-btn text color="primary" @click="menu_start = false">取消</v-btn>
														<v-btn text color="primary" @click="okDateChange">确认查询</v-btn>
													</v-date-picker>
												</div>
												<div class="px-2"></div>
												<div style="width: 290px;"><v-date-picker color="red" locale="zh" v-model="old_data_end"></v-date-picker></div>
											</div>
										</v-menu>
									</div>
								</div>
							</div>
						</dv-border-box-10>
					</div>
				</div>
			</div>
		</headerBody>

		<div class="bodybottom">
			<div class="bodybottomleft">
				<div class="d-flex justify-center" style="height: 60%;">
					<div class="bodybottom-1">
						<dv-border-box-10>
							<div style="padding: 15px;">
								<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
									<dv-decoration-3 style="width:100px;height:3vh;" />
									<span class="pl-2 jricaipu ml-3">
										数据报送
										<span class="px-3" style="font-size: 12px;">{{ date_sart }}~{{ date_end }}</span>
									</span>
								</div>
								<dv-decoration-2 :dur="2" style="width:100%;height:2vh;" />

								<div><dv-scroll-board3 :config="boasongList" style="width:100%;height:40vh"></dv-scroll-board3></div>
							</div>
						</dv-border-box-10>
					</div>
					<div class="bodybottom-2">
						<dv-border-box-10>
							<div style="padding: 15px;">
								<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
									<dv-decoration-3 style="width:100px;height:3vh;" />
									<span class="pl-2 jricaipu ml-3 mr-3">{{ rankPaiHang_Type[rankPaiHang_TypeSelected].text }}排行</span>
									<div>
										<v-radio-group @change="rankPaiHangChange" v-model="rankPaiHang_TypeSelected" row dense dark flat hide-details style="margin-top: 0;">
											<v-radio v-for="(item, index) in rankPaiHang_Type" :key="index" :value="item.id" :label="item.text"></v-radio>
										</v-radio-group>
									</div>
								</div>
								<dv-decoration-2 :dur="2" style="width:100%;height:2vh;" />

								<div style="width:100%;height:40vh" id="rank"></div>
							</div>
						</dv-border-box-10>
					</div>
				</div>
				<div class="bodybottom-3" style="height: 40%;">
					<dv-border-box-10>
						<div style="padding: 15px;">
							<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
								<dv-decoration-3 style="width:100px;height:3vh;" />
								<span class="pl-2 jricaipu ml-3">
									往年与今年数据对比
									<span style="font-size: 13px;" class="px-6">往年：{{ duibi_start_date }}~{{ duibi_end_date }}</span>
									<span style="font-size: 13px;color: yellow;" class="px-6">今年：{{ date_sart }}~{{ date_end }}</span>
									
								</span>
							</div>
							<div style="width: 100%;" class="d-flex justify-space-between">
								<div style="width:32%;height:calc(24vh - 10px;" id="duibi_renshu"></div>
								<div style="width:32%;height:calc(24vh - 10px;" id="duibi_huafei"></div>
								<div style="width:32%;height:calc(24vh - 10px;" id="duibi_renjun"></div>
							</div>
						</div>
					</dv-border-box-10>
				</div>
			</div>
			<div class="bodybottomright">
				<div class="bodybottom-4" style="height: 100%;">
					<dv-border-box-10>
						<div style="padding: 15px;">
							<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
								<dv-decoration-3 style="width:100px;height:3vh;" />
								<span class="pl-2 jricaipu ml-3">旅游人数趋势</span>
							</div>
							<div style="width:100%;height:23vh;" id="lvyou_renshu"></div>
							<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
								<dv-decoration-3 style="width:100px;height:3vh;" />
								<span class="pl-2 jricaipu ml-3">旅游花费趋势</span>
							</div>
							<div style="width:100%;height:23vh;" id="lvyou_huafei"></div>
							<div class=" bodybottom-title d-flex justify-start" style="margin-bottom: 10px;line-height: 3vh;">
								<dv-decoration-3 style="width:100px;height:3vh;" />
								<span class="pl-2 jricaipu ml-3">人均花费趋势</span>
							</div>
							<div style="width:100%;height:calc(23vh - 15px);" id="lvyou_renjun"></div>
						</div>
					</dv-border-box-10>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import headerBody from './headerBody';
// import EZUIKit from './ezuikit';

var echarts = require('./../assets/echarts.min.js');

export default {
	name: 'bodyElWk',
	components: { headerBody },
	data: () => ({
		showLoaddingDataV: true, //显示全屏加载器。
		headerWeek: {},
		dateType: [{ id: 2, text: '按周' }, { id: 3, text: '按月' }],
		dateTypeSelected: 2,
		jiejiariList: [],
		jiejiariList_selected: '',
		isShowJiejiariCheckbox: false,
		old_data_start: '',
		old_data_end: '',
		date_sart: new Date().toISOString().substr(0, 10), //对比时间段结束-开始
		date_end: new Date().toISOString().substr(0, 10), //对比时间段结束-结束
		duibi_start_date: '', //对比时间段-开始
		duibi_end_date: '', //对比时间段-结束
		menu_start: false,
		menu_end: false,
		modal: false,
		refreshTime: 60, //刷新间隙。
		autoRefreshTimeNum: 0,
		boasongList: {},
		rankPaiHang_Type: [{ id: 0, text: '旅游人数' }, { id: 1, text: '旅游花费' }, { id: 2, text: '人均花费' }],
		rankPaiHang_TypeSelected: 0,
		rankPaiHang: [null, null, null],
		rankChart: null,

		dataTrand: [null, null, null] //数据趋势。
	}),
	created() {
		//赋值默认的时间段。
		// 本年和去年,默认展示是今年以来的数据。
		let s_d = new Date();
		this.date_end = new Date().toISOString().substr(0, 10);
		let s_d1 = new Date();
		s_d1.setDate(s_d1.getDate()-60)
		this.date_sart = s_d1.toISOString().substr(0, 10);
		this.old_data_end = this.date_end;
		this.old_data_start = this.date_sart;

		let e_d = new Date();
		e_d.setYear(e_d.getFullYear() - 1);
		let e_d2 = new Date();
		e_d2.setYear(e_d2.getFullYear() - 1);
		e_d2.setDate(e_d2.getDate()-60)
		
		this.duibi_end_date = e_d.toISOString().substr(0, 10);
		this.duibi_start_date = e_d2.toISOString().substr(0, 10);
	},
	async mounted() {
		this.jshiRefreshData();
		this.headerWeekFun();
		await this.initfun();
	},
	watch: {},
	methods: {
		resetDate() {
			this.$nextTick(function() {
				this.old_data_end = this.date_end;
				this.old_data_start = this.date_sart;
			});
		},
		okDateChange() {
			let s_d = new Date();
			this.date_end = this.old_data_end;
			this.date_sart = this.old_data_start;
			
			let e_d_1 = new Date(this.date_sart);
			let e_d_2 = new Date(this.date_end);
			e_d_1.setYear(e_d_1.getFullYear() - 1);
			e_d_2.setYear(e_d_2.getFullYear() - 1);
			
			this.duibi_end_date = e_d_2.toISOString().substr(0, 10);
			this.duibi_start_date = e_d_1.toISOString().substr(0, 10);
			
			this.initfun();
		},
		jiejiariListChange(e){
			let item = this.jiejiariList.filter((el)=>el.text==e)
			if(item.length==0){
				vm.$store.commit('setshowtips',{msg:'没有数据',color:'red'})
				return;
			}
			let d = item[0];
			this.date_sart = d.start;
			this.date_end = d.end;
			let e_d_1 = new Date(this.date_sart);
			let e_d_2 = new Date(this.date_end);
			e_d_1.setYear(e_d_1.getFullYear() - 1);
			e_d_2.setYear(e_d_2.getFullYear() - 1);
			this.duibi_start_date = e_d_1.toISOString().substr(0, 10);
			this.duibi_end_date = e_d_2.toISOString().substr(0, 10);
			this.initfun();
		},
		dateTypeChange(e){
			this.$nextTick(function(){
				this.initfun();
			})
		},
		//计时刷新数据。
		jshiRefreshData() {
			let t = this;
			clearInterval(this.timideid_refrsh);
			this.timideid_refrsh = setInterval(function() {
				let nowdate = new Date().getTime();
				let old_time = localStorage.getItem('timedate') || null;
				if (old_time !== null) {
					let cha = (nowdate - old_time) / 1000;

					if (cha > t.refreshTime) {
						console.log('超过了刷新时间，开始刷新。');
						localStorage.setItem('timedate', nowdate);
						
						t.initfun(false);
					}
					t.autoRefreshTimeNum = Math.floor(cha);
				} else {
					//存储计时，第一次计时。
					localStorage.setItem('timedate', nowdate);
				}
			}, 1000);
		},
		//时间显示
		headerWeekFun() {
			let t = this;
			clearInterval(this.timideid);
			this.timideid = setInterval(function() {
				let d = new Date();
				let day = ['日', '一', '二', '三', '四', '五', '六'];
				t.headerWeek = {
					first: d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日',
					two: '周' + day[d.getDay()],
					three: d.getHours() + '时' + d.getMinutes() + '分' + d.getSeconds() + '秒'
				};
			}, 1000);
		},
		
		//获取节假日的数据列表。
		async datav_queryHoliday() {
			let jiejiariList = await this.$api.datav_queryHoliday(this.date_sart.substr(0, 4)).catch(e => {});
			if (!jiejiariList) return;
			if (this.jiejiariList_selected) {
				let p = this.jiejiariList_selected;
				let isAlready = jiejiariList.findIndex(el => el.text == p);
				if (isAlready >= -1) {
					return;
				}
				//如果已选择的节假日不在本列表中，则重新选择默认的第一个，否则不选择。
				this.jiejiariList_selected = jiejiariList[0].text;
			}
			this.jiejiariList = jiejiariList;
		},
		//获取大屏刷新间隔数据
		async datav_homeBaseData() {
			let refreshTime = await this.$api.datav_homeBaseData().catch(e => {});
			if (!refreshTime) return;
			this.refreshTime = refreshTime.refresh;
		},

		//获取报送记录数据
		async datav_getReportList() {
			let list = await this.$api.datav_getReportList({ start: this.date_sart, end: this.date_end }).catch(e => {});
			if (!list) return;
			let lsit_for = list.map(el => [el]);
			this.boasongList = { data: [...lsit_for] };
		},
		//处理排行榜
		formartLvyouZoushi(data, color = 'rgba(33,70,255,1)', type = 'bar', yname = '人数', xname = '日期') {
			color = [
				'rgba(33,70,255,1)',
				'rgba(103,58,183,1)',
				'rgba(15, 137, 244, 1.0)',
				'rgba(255, 85, 28, 1.0)',
				'rgba(255, 8, 61, 1.0)',
				'rgba(76,175,80,1)',
				'rgba(255, 255, 0, 1.0)',
				'rgba(255,143,0,1)',
				'rgba(225, 90, 178, 1.0)',
				'rgba(77,182,172,1)',
				'rgba(0, 221, 181, 1.0)',
				'rgba(29, 10, 176, 1.0)'
			];
			let source = [];
			let color_obj = {};
			let d = data.series_data[0].data;
			source = d.map((el, index) => {
				let ci = index;
				if (ci > color.length) ci = color.length - 1;
				let tit = data.xAxis_data[ci];
				color_obj[tit] = color[ci];
				return [el, tit];
			});

			return {
				tooltip: {
					show: true,
					trigger: 'axis'
				},
				title: {
					text: '时间' + this.date_sart + '~' + this.date_end,
					textStyle: {
						color: '#bec2ff'
					},
					left: 'center'
				},
				grid: {
					top: 30,
					bottom: 12,
					left: 0,
					right: 50,
					containLabel: true
				},
				color: color,
				dataset: {
					source: [...source]
				},

				xAxis: {
					name: xname,
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					},
					splitLine: {
						lineStyle: {
							// 使用深浅的间隔色
							color: ['rgba(66, 69, 255, 0.4)', 'rgba(20, 82, 255, 0.6)']
						}
					}
				},
				yAxis: {
					type: 'category',
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					}
				},
				series: [
					{
						itemStyle: {
							color: function(param) {
								return color_obj[param.name] || '#5470c6';
							}
						},
						seriesLayoutBy: 'column',
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: xname,
							// Map the "product" column to Y axis
							y: yname
						},
						label: {
							show: true,
							position: 'right'
						}
					}
				]
			};
		},
		//获取排行榜数据。
		async datav_dataRanking() {
			//连续请求三次。
			//初始化图表。
			this.rankPaiHang = [null, null, null];
			let node = document.querySelector('#rank');
			var rankChart = echarts.init(node);
			this.rankChart = rankChart;
			//请求旅游人数。
			let list1 = await this.$api.datav_dataRanking({ start: this.date_sart, end: this.date_end, date_type: this.dateTypeSelected, category_id: 1 }).catch(e => {});
			if (!list1) return;
			//排序，高到底。
			
			let pls = list1.series_data[0].data.map((el, index) => {
				return { name: list1.xAxis_data[index], data: el };
			});
			pls = pls.sort((a, b) => b.data - a.data);
			list1.series_data[0].data = pls.map(el => el.data);
			list1.xAxis_data = pls.map(el => el.name);
			//处理数据1.
			let opts = this.formartLvyouZoushi(list1, '', 'bar', '名称', '人数');

			this.rankPaiHang.splice(0, 1, opts);
			rankChart.setOption(opts,{notMerge:true});
			await this.wait(300);
			//处理数据2.
			let list2 = await this.$api.datav_dataRanking({ start: this.date_sart, end: this.date_end, date_type: this.dateTypeSelected, category_id: 2 }).catch(e => {});
			if (!list2) return;
			//排序，高到底。
			pls = list2.series_data[0].data.map((el, index) => {
				return { name: list2.xAxis_data[index], data: el };
			});
			pls = pls.sort((a, b) => b.data - a.data);
			list2.series_data[0].data = pls.map(el => el.data);
			list2.xAxis_data = pls.map(el => el.name);
			//处理数据1.
			opts = this.formartLvyouZoushi(list2, '', 'bar', '名称', '万元');
			this.rankPaiHang.splice(1, 1, opts);
			
			await this.wait(300);
			//处理数据3.
			let list3 = await this.$api.datav_dataRanking({ start: this.date_sart, end: this.date_end, date_type: this.dateTypeSelected, category_id: 3 }).catch(e => {});
			if (!list3) return;
			//排序，高到底。
			pls = list3.series_data[0].data.map((el, index) => {
				return { name: list3.xAxis_data[index], data: el };
			});
			pls = pls.sort((a, b) => b.data - a.data);
			list3.series_data[0].data = pls.map(el => el.data);
			list3.xAxis_data = pls.map(el => el.name);
			//处理数据1.
			opts = this.formartLvyouZoushi(list3, '', 'bar', '名称', '万元');
			this.rankPaiHang.splice(2, 1, opts);
		},
		rankPaiHangChange(e) {
			if (!this.rankPaiHang[e]) {
				vm.$store.commit('setshowtips', { msg: '没有数据', color: 'red' });
				return;
			}
			this.rankChart.setOption(this.rankPaiHang[e],{notMerge:true});
		},
		//处理旅游趋势图表
		formartQushi(data, color = 'rgba(33,70,255,1)', type = 'line', yname = '人数', xname = '日期') {
			color = color || [
				'rgba(33,70,255,1)',
				'rgba(103,58,183,1)',
				'rgba(15, 137, 244, 1.0)',
				'rgba(255, 85, 28, 1.0)',
				'rgba(255, 8, 61, 1.0)',
				'rgba(76,175,80,1)',
				'rgba(255, 255, 0, 1.0)',
				'rgba(255,143,0,1)',
				'rgba(225, 90, 178, 1.0)',
				'rgba(77,182,172,1)',
				'rgba(0, 221, 181, 1.0)',
				'rgba(29, 10, 176, 1.0)'
			];
			let series = [];
			data.series_data = [data.series_data[0]];
			for (let i = 0; i < data.series_data.length; i++) {
				series.push({
					data: data.series_data[i].data,
					name: data.series_data[i].name,
					type: type,
					smooth: true,
					areaStyle: {
						opacity: 0.4
					},
					label: {
						show: true,
						position: 'top'
					},
					markPoint: {
						data: [{ type: 'max', name: 'Max' }, { type: 'min', name: 'Min' }]
					}
				});
			}
			return {
				grid: {
					right: 40,
					bottom: 26,
					top: 60,
					left:80
				},

				color: color,
				title: {
					text: this.date_sart + '~' + this.date_end,
					textStyle: {
						color: '#bec2ff',
						fontSize: 15
					},
					left: 'center'
				},
				tooltip: {
					show: true,
					trigger: 'axis'
				},
				xAxis: {
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					},
					type: 'category',
					data: data.xAxis_data,
					name: xname
				},
				yAxis: {
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					},
					type: 'value',
					name: yname,
					splitLine: {
						lineStyle: {
							// 使用深浅的间隔色
							color: ['rgba(66, 69, 255, 0.1)', 'rgba(20, 82, 255, 0.2)']
						}
					}
				},
				series: series
			};
		},
		//数据趋势
		async datav_dataTrend() {
			//人数趋势
			let node = document.querySelector('#lvyou_renshu');
			var rankChart = echarts.init(node);
			let list = await this.$api.datav_dataTrend({ start: this.date_sart, end: this.date_end, date_type: this.dateTypeSelected }).catch(e => {});

			if (!list) return;
			let opts = this.formartQushi(list[0], '', 'line', '人数', '万人');
			rankChart.setOption(opts,{notMerge:true});

			let node2 = document.querySelector('#lvyou_huafei');
			var rankChart2 = echarts.init(node2);
			let opts2 = this.formartQushi(list[1], ['rgba(255, 255, 0, 1.0)'], 'line', '花费', '万元');
			rankChart2.setOption(opts2,{notMerge:true});

			let node3 = document.querySelector('#lvyou_renjun');
			var rankChart3 = echarts.init(node3);
			let opts3 = this.formartQushi(list[2], ['rgba(255,143,0,1)'], 'line', '人均花费', '元');
			rankChart3.setOption(opts3,{notMerge:true});
		},
		formatDuibi(data, name, rtext) {
			let color = ['rgba(33,70,255,1)', 'rgba(255, 157, 0, 1.0)'];
			const data_1 = data[0]?.series_data[0].data;
			const data_2 = data[1]?.series_data[0].data;
			let chart_opts = {
				grid: {
					right: 40,
					bottom: 50,
					left:80
				},
				legend: {
					orient: 'vertical',
					left: 'left',
					bottom: 'bottom',
					textStyle: {
						color: 'rgba(124, 144, 255, 1.0)'
					}
				},
				color: color,
				title: {
					text: name,
					left: 'center',
					textStyle: {
						color: 'rgba(124, 144, 255, 1.0)'
					}
				},
				tooltip: {
					show: true,
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					data: data[0].xAxis_data,
					name: '日期',
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					}
				},
				yAxis: {
					type: 'value',
					name: rtext,
					axisLabel: {
						color: 'rgba(124, 144, 255, 1.0)'
					},
					splitLine: {
						lineStyle: {
							// 使用深浅的间隔色
							color: ['rgba(66, 69, 255, 0.1)', 'rgba(20, 82, 255, 0.2)']
						}
					}
				},
				series: [
					{
						data:data_2 ,
						type: 'bar',
						smooth: true,
						name: '往年'+this.duibi_start_date +'~'+this.duibi_end_date ,
						areaStyle: {
							opacity: 0.5
						},
						label: {
							show: true,
							position: 'top'
						}
					},
					{
						data: data_1,
						type: 'bar',
						smooth: true,
						name: '今年'+this.date_sart+'~'+this.date_end,
						areaStyle: {
							opacity: 0.5
						},
						label: {
							show: true,
							position: 'top'
						}
					}
				]
			};

			return chart_opts;
		},
		wait(wait=1000){
			return new Promise((res,rej)=>{
				setTimeout(function() {
					res();
				}, wait);
			})
		},
		//数据对比。datav_dataComparison
		async datav_dataComparison() {
			//连续请求三次。
			//初始化图表。

			let node = document.querySelector('#duibi_renshu');
			var rankChart = echarts.init(node);
			//请求旅游人数。
			let list1 = await this.$api
				.datav_dataComparison({
					start: {
						start_date: this.date_sart,
						end_date: this.date_end,
						district_id: 0
					},
					end: {
						start_date: this.duibi_start_date,
						end_date: this.duibi_end_date,
						district_id: 0
					},
					date_type: this.dateTypeSelected,
					category_id: 1
				})
				.catch(e => {});
			if (!list1) return;

			//处理数据1.
			let opts = this.formatDuibi(list1, '旅游人数', '人数（万人）');
			rankChart.setOption(opts,{notMerge:true});

			let node2 = document.querySelector('#duibi_huafei');
			var rankChart2 = echarts.init(node2);
			//请求旅游人数。
			let list2 = await this.$api
				.datav_dataComparison({
					start: {
						start_date: this.date_sart,
						end_date: this.date_end,
						district_id: 0
					},
					end: {
						start_date: this.duibi_start_date,
						end_date: this.duibi_end_date,
						district_id: 0
					},
					date_type: this.dateTypeSelected,
					category_id: 2
				})
				.catch(e => {});
			if (!list2) return;
			await this.wait(300);
			//处理数据2.
			let opts2 = this.formatDuibi(list2, '旅游花费', '万元');
			rankChart2.setOption(opts2,{notMerge:true});
			await this.wait(300);
			let node3 = document.querySelector('#duibi_renjun');
			var rankChart3 = echarts.init(node3);
			//请求旅游人数。
			let list3 = await this.$api
				.datav_dataComparison({
					start: {
						start_date: this.date_sart,
						end_date: this.date_end,
						district_id: 0
					},
					end: {
						start_date: this.duibi_start_date,
						end_date: this.duibi_end_date,
						district_id: 0
					},
					date_type: this.dateTypeSelected,
					category_id: 3
				})
				.catch(e => {});
			if (!list3) return;

			//处理数据2.
			let opts3 = this.formatDuibi(list3, '人均花费', '元');
			rankChart3.setOption(opts3,{notMerge:true});
		},
		async initfun(isTrue) {
			
			if(isTrue!==false){
				this.showLoaddingDataV=true;
			}
			// await this.datav_homeBaseData();
			//定时器刷新数据。
			try{
				
				
				await this.datav_queryHoliday().catch(e=>{})
				
				//数据报送记录列表
				await this.datav_getReportList().catch(e=>{})
				
				//获取排行榜数据。
				await this.datav_dataRanking().catch(e=>{})
				
				//数据趋势。
				await this.datav_dataTrend().catch(e=>{})
				
				//数据对比
				await this.datav_dataComparison().catch(e=>{})
				await this.wait(300);
				this.showLoaddingDataV=false;
			}catch(e){
				//TODO handle the exception
				console.error(e)
			}
		}
	}
};
</script>
<style scoped>
.bodybottom {
	height: calc(100vh - 120px);
	padding: 0 15px;
	/* overflow: hidden; */
	padding-top: 1vh;
	display: flex;
}

.bodybottomleft {
	width: 75vw;
	display: flex;
	flex-flow: column;
	height: 100%;
}
.bodybottom-1,
.bodybottom-2 {
	height: 100%;
}
.bodybottomright {
	width: 25vw;
	height: 100%;
}
.bodybottom-1 {
	width: 30vw;
}
.bodybottom-2 {
	width: 45vw;
	padding-left: 2vh;
	padding-right: 2vh;
}
.bodybottom-3 {
	width: 100%;
	padding-top: 2vh;
	padding-right: 2vh;
}
/*滚动条样式*/
.bodyScroolCaip::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 4px;
}
.bodyScroolCaip::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 5px;
	-webkit-box-shadow: inset005pxrgba(6, 101, 255, 0.2);
	background: rgba(1, 132, 255, 0.2); /*设置滚动条颜色*/
}
.bodyScroolCaip::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	-webkit-box-shadow: inset005pxrgba(1, 145, 255, 0.2);
	border-radius: 0;
	background: rgba(0, 0, 0, 0.1);
}
.dataShow {
	position: relative;
	z-index: 100;
	cursor: pointer;
	padding-left: 10px;
}
.dataShowChildren {
	position: absolute;
	display: none;
	bottom: 28px;
}
.dataShowChildren.show {
	display: block;
}

.clcapipinshujuBG {
	box-shadow: 0 0 24px 3px rgba(8, 140, 255, 0.4) inset;
	border: 1px solid rgba(0, 85, 255, 0.6);
	border-radius: 50%;
	width: 53px;
	height: 53px;
	display: flex;
	justify-content: center;
	align-content: center;
}
.jricaipu {
	background-image: linear-gradient(to right, rgba(0, 85, 255, 1), rgba(0, 0, 0, 0));
	border-radius: 5px;
	padding-left: 10px;
	color: #fff;
	/* font-weight: bold; */
}
.bodybottom-title {
	font-size: 18px;
	/* font-weight: bold; */
	padding-top: 8px;
	color: rgba(255, 255, 255, 1);
	padding-right: 15px;
	display: inline-block;
}
.title-wk {
	height: 75px;
}
.title {
	font-size: 37px !important;
	letter-spacing: 6px !important;
	color: white;
	font-weight: bold;
}
</style>
