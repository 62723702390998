import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  isLogin:false,
	  showtips:{show:false,msg:'提示信息',color:'red'}
  },
  mutations: {
	  setIsLogin(state,login){
		  state.isLogin = login
	  },
	  setshowtips(state,obj){
		  let defaultValue = {show:true,msg:'提示信息',color:'red',...obj}
	  		  state.showtips = defaultValue
	  }
  },
  actions: {
  },
  modules: {
  }
})
