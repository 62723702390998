<template>
	<div style="width: 100%;position: relative;line-height: 0;">
		<canvas style="width:100%;height:110px" id="headerCVG"></canvas>
		<div style="width: 100%; height: 100%; position: absolute;top: 0;left: 0;z-index: 55; overflow: hidden;"><slot name="default"></slot></div>
	</div>
</template>

<script>
import { CRender, extendNewGraph } from '@jiaminghi/c-render';
const util = require('@jiaminghi/c-render/lib/plugin/util');

const headerGui = require('./renderHeader.js').default;
export default {
	name: 'headerBody',

	data: () => ({}),
	async mounted() {
		const canvas = document.querySelector('#headerCVG');
		const render = new CRender(canvas);
		const [w, h] = render.area;
		render.add(headerGui.rheader(render));
		render.add(headerGui.rheaderLine(render));
	},
	methods: {}
};
</script>
